<template>
    <div class="card">
        <div class="block">
            <b>Pre-survey.</b> Please answer the following questions first.
        </div>

        <div class="block">
            <b>Q1.</b> Tell your gender.<br />
            <div class="flex">
                <v-radio-group v-nano.required v-model="gender" row>
                    <div class="check-item"><v-radio label="Male" value="Male"/></div>
                    <div class="check-item"><v-radio label="Female" value="Female"/></div>
                    <div class="check-item"><v-radio label="Other" value="Other"/></div>
                </v-radio-group>
            </div>
        </div>
                
        <div class="block">
            <b>Q2.</b> Tell your age. (Please input a single-byte number.)<br />
            <input type="number" min="0" max="120" v-nano.required v-model="agetext" />
        </div>
        
        <div class="block">
            <b>Q3.</b> Tell your English level.<br />
            <div class="flex">
                <v-radio-group v-nano.required v-model="Englevel" row>
                    <div class="check-item"><v-radio label="Native" value="Native"/></div>
                    <div class="check-item"><v-radio label="Business" value="Business"/></div>
                    <div class="check-item"><v-radio label="Daily Conversation" value="Daily Conversation"/></div>
                    <div class="check-item"><v-radio label="Basic (Minimum conversation / learning)" value="Basic (Minimum conversation / learning)"/></div>
                </v-radio-group>
            </div>
        </div>
        
        <div class="block">
            <b>Q4.</b> Tell your native language.<br />
            <div class="flex">
                <v-col cols="5"><v-select v-nano.required solo v-model="selectlanguage" :items="languages" label="Select one"></v-select></v-col>
                <div v-if="selectlanguage=='Other'">
                     <v-text-field v-nano v-model="languagetext" label="Specify your language..." style="width:300px" clearable />
                </div>
            </div>
        </div>

        <div class="block">
            <b>Q5.</b>Tell the situations in which you use synthetic voices. (Multiple answers are acceptable)
            <div class="flex">
                <div class="check-item2"><v-checkbox value="Voice assistants in smartphone" v-nano.required v-model="situations" label="Voice assistants in smartphone" /></div>
                <div class="check-item"><v-checkbox value="Voice guidance in commercial facilities" v-nano.required v-model="situations" label="Voice guidance in commercial facilities" /></div>
            </div>
            <div class="flex">
                <div class="check-item2"><v-checkbox value="Evaluated synthesized voices before" v-nano.required v-model="situations" label="Evaluated synthesized voices before" /></div>
                <div v-if="situations.includes('Evaluated synthesized voices before')">
                     <v-text-field v-nano v-model="evaluatenum" label="Specify the number of past evaluations..." style="width:350px" clearable />
                </div>
            </div>
            <div class="flex">
                <div class="check-item2"><v-checkbox value="Speech Researchers" v-nano.required v-model="situations" label="Speech Researchers" /></div>
                <div v-if="situations.includes('Speech Researchers')">
                     <v-text-field v-nano v-model="field" label="Specify your field..." style="width:300px" clearable />
                </div>
            </div>
            <div class="flex">
                <div class="check-item2"><v-checkbox value="Others" v-nano.required v-model="situations" label="Others" /></div>
                <div v-if="situations.includes('Others')">
                     <v-text-field v-nano v-model="situationtext" label="Specify situation..." style="width:300px" clearable />
                </div>
                </div>
        </div>

        <div class="block">
            <b>Q6.</b> In this task, you will listen some voices. Please tell us about your listening environment.
            <div class="flex">
                <v-radio-group v-nano.required v-model="environment" row>
                    <div class="check-item2"><v-radio label="Earbud headphones" value="Earbud headphones"/></div>
                    <div class="check-item2"><v-radio label="On/over-ear headphones" value="On/over-ear headphones"/></div>
                    <div class="check-item2"><v-radio label="Loud speaker(s)" value="Loud speaker(s)"/></div>
                    <div class="check-item2"><v-radio label="PC built-in speaker(s)" value="PC built-in speaker(s)"/></div>
                    <div class="check-item"><v-radio label="Other" value="Other"/></div>
                    <div v-if="environment=='Other'">
                         <v-text-field v-nano v-model="environmenttext" label="Specify environment..." style="width:300px" clearable />
                    </div>
                </v-radio-group>
            </div>
        </div>

        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        gender: "",
        age: "",
        agetext: "",
        Englevel: "",
        languages: ["English (UK)", "English (US)", "English (Australia)", "English (Canada)", "English (India)", "English (Ireland)", "English (New Zealand)", "English (Singapore)", "English (South Africa)", "Chinese, Simplified", "Chinese, Traditional", "Chinese, Traditional (Hong King)", "Spanish", "Spanish (Latin America)", "French", "French (Canada)", "German", "Russian", "Portuguese (Brazil)", "Portuguese (Portugal)", "Italian", "Korean", "Turkish", "Dutch", "Arabic", "Thai", "Swedish", "Danish", "Vietnamese", "Norwegian Bokmal", "Polish", "Finnish", "Indonesian", "Hebrew", "Greek", "Romanian", "Hungarian", "Czech", "Catalan", "Slovak", "Ukrainian", "Croatian", "Malay", "Hindi", "Japanese", "Other"],
        selectlanguage: "",
        languagetext: '',
        situations: [],
        evaluatenum: '',
        field: '',
        situationtext: '',
        environment: "",
        environmenttext: '',
    })
};

</script>

<style scoped>
.card {
    width: 800px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=number] {
    width: 20%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
input[type=text] {
    width: 300%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
    justify-content: left;
    -webkit-align-items: center;
    align-items: center;
    white-space: nowrap;
}
.check-item {
    width: 200px;
    padding: 10px;
    display: inline!important;
}
.check-item>input {
    margin-right: 5px;
}
.check-item2 {
    width: 320px;
    padding: 10px;
}
.check-item2>input {
    margin-right: 5px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>
