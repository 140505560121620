<template>
    <div class="card">
        <h1><b>Easy Task</b></h1>
            <ul>
                <li>From here, the Easy Tasks start.</li>
                <li>There are 12 questions in total.</li>
                <li>You can start it by clicking on the "Go To the Easy Task" button below.</li>
                <li><font color="red">Note that if you give a distracted answer here, the entire HIT may be rejected</font>.</li>
            </ul>
            
        <p class="mb5"></p>

        <div class="next-btn-wrapper">
            <button @click="canSubmit ? submit() : false">Go To the Easy Task</button>
        </div>

    </div>

</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
    })
};
</script>

<style scoped>
.card {
    width: 800px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
p.mb5 {
    margin-bottom: 5em;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 200px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>
