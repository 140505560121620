<template>
    <div class="card">
        <h1><b>Human voices</b></h1>
            <ul>
                <li>Here are some examples of natural non-synthesized human voices.</li>
                <li>You cannot move onto the first question until you listen to all sample voices at least once for each.</li>
                <li><font color="red">Note that you will not be able to listen the natural human voices after this.</font></li>
                <br />
                    <p>Natural human voice samples :</p>
                    <table>
                    <tr>
                    <td>
                    <ul>
                    <li> sample 1 : </li>
					<v-btn elevation="2" class="play-btn" :disabled="!audio.A.canPlay || audio.B.playing || audio.C.playing" @click="togglePlaying('A');">
                      <v-icon v-if="!audio.A.canPlay">mdi-cached</v-icon>
                      <v-icon v-else-if="!audio.A.playing">mdi-play</v-icon>
                      <v-icon v-else>mdi-stop</v-icon>
                    </v-btn>
                    </ul>
                    </td>
                    <td>
                    <ul>
                    <li> sample 2 : </li>
					<v-btn elevation="2" class="play-btn" :disabled="!audio.B.canPlay || audio.A.playing || audio.C.playing" @click="togglePlaying('B');">
                      <v-icon v-if="!audio.B.canPlay">mdi-cached</v-icon>
                      <v-icon v-else-if="!audio.B.playing">mdi-play</v-icon>
                      <v-icon v-else>mdi-stop</v-icon>
                    </v-btn>
                    </ul>
                    </td>
                    <td>
                    <ul>
                    <li> sample 3 : </li>
					<v-btn elevation="2" class="play-btn" :disabled="!audio.C.canPlay || audio.A.playing || audio.B.playing" @click="togglePlaying('C');">
                      <v-icon v-if="!audio.C.canPlay">mdi-cached</v-icon>
                      <v-icon v-else-if="!audio.C.playing">mdi-play</v-icon>
                      <v-icon v-else>mdi-stop</v-icon>
                    </v-btn>
                    </ul>
                    </td>
                    </tr>
                    </table>
                   
            </ul>
            
        <p class="mb5"></p>

        <div class="next-btn-wrapper">
            <button :disabled="!allPlayedOnce || audio.A.playing || audio.B.playing || audio.C.playing" @click="canSubmit ? submit() : false">Go To the first question</button>
        </div>

    </div>

</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({//変数の定義=>View変える変数はここにかかないといけない
        defaultNanoProps: {
            "id_0": "/static/LJSpeech-1.1/LJ001-0001.wav",
            "id_1": "/static/LJSpeech-1.1/LJ001-0003.wav",
            "id_2": "/static/LJSpeech-1.1/LJ001-0005.wav",
        },
        audio: {
            A: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
            B: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
            C: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
        },
        audioLabels: ["A", "B", "C"],
    checkBox: {
          ERR: false,
        },
    }),
    computed: {
        allPlayedOnce() {
            try {
                var ret = true;
                for(var i in this.audioLabels) ret = ret && this.audio[this.audioLabels[i]].playedOnce;
                return ret;
            } catch {
                return false;
            }
        }
    },
    methods: {
        toggleBtn(label){
            var audio = this.audio[label];
            audio.playedOnce = 1;
            console.log(this.allPlayedOnce);

            if( !audio.obj.paused ){
                this.nano.ans[`start_time${label}`]["Stop"].push(Math.floor( new Date().getTime() / 1000 ));
                audio.obj.currentTime = 0;
                audio.obj.pause();
            }
            else{
                this.nano.ans[`start_time${label}`]["Start"].push(Math.floor( new Date().getTime() / 1000 ));
                audio.obj.play();
            }
        },
        togglePlaying(label){
            var audio = this.audio[label];
            if (audio.playing == true) {
                audio.playing = false;
            } else {
                audio.playing = true;
            }
            this.toggleBtn(label);
        },
        initAudio() {
            for(var i in this.audioLabels){
                let label = this.audioLabels[i];

                this.$set(this.audio, label, {
                    obj: new Audio(),
                    playing: false,
                    canPlay: false,
                    playedOnce: 0
                });

                let audio = this.audio[label];

                audio.obj.addEventListener("canplaythrough", () => {
                    audio.canPlay = true;
                })

                audio.obj.src = this.nano.props[`id_${i}`];
                this.nano.ans[`start_time${label}`] = {"Start": [], "Stop": []};

                audio.obj.addEventListener("ended", ()=>{
                        this.nano.ans[`start_time${label}`]["Stop"].push(Math.floor( new Date().getTime() / 1000 ));
                        audio.obj.currentTime = 0;
                        audio.playing = false;
                });

            }

        }
    },
    watch: {
        "nano.props"() {
            this.initAudio();
        }
    },
};
</script>

<style scoped>
.card {
    width: 800px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
p.mb5 {
    margin-bottom: 5em;
}
#passed_time_box {
  border: 2px solid #f00;
  background: #fdd;
  width: 30%;
  font-weight: bold;
  margin: 10px;
  padding: 5px;
  font-size: 1.5em;
}
.play-btn {
  width: 100px;
  height: 40px;
  font-size: 1.5em;
  font-weight: bold;
}
table{
  margin: 10px;
  width: 800px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 200px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>